<template>
  <div>
    <div class="bg-colorWhite" style="border-radius: 20px">
      <b-row class="d-flex align-items-start justify-content-start py-1">
        <b-col md="12" class="px-3 py-1">
          <h3 class="font-weight-bolder text-colorBlack">My Classes</h3>
          <p class="font-weight-bold text-colorGray mb-50">
            Your all Classes display here.
          </p>
        </b-col>
      </b-row>
      <b-card class="mx-2" style="border-radius: 20px">
        <b-table
          stacked="md"
          :fields="fieldsClass"
          :items="Class"
          thead-class="tableHeadColor"
          small
          rounded
          bordered
        >
          <template #head()="data">
            <div class="py-50">
              <span class="font-weight-bolder text-colorBlack">{{
                data.label
              }}</span>
            </div>
          </template>

          <template #cell(Class)="row">
            <div class="py-2">
              <b-link :to="{ name: 'InstructorClassesDetail' }">
                <span
                  class="font-weight-bolder text-colorBlack m-0"
                  style="font-size: 0.8rem"
                >
                  {{ row.item.Class ? row.item.Class : "" }}
                </span>
              </b-link>
            </div>
          </template>
          <template #cell(Students)="row">
            <span
              class="font-weight-bolder text-colorBlack m-0"
              style="font-size: 0.8rem"
            >
              {{ row.item.Students ? row.item.Students : "" }}
            </span>
          </template>
          <template #cell(TotalSubjects)="row">
            <div>
              <span
                class="font-weight-bolder text-colorBlack m-0"
                style="font-size: 0.8rem"
              >
                {{ row.item.TotalSubjects ? row.item.TotalSubjects : "" }}
              </span>
            </div>
          </template>
          <template #cell(Lectures)="row">
            <div>
              <span
                class="font-weight-bolder text-colorBlack m-0"
                style="font-size: 0.8rem"
              >
                {{ row.item.Lectures ? row.item.Lectures : "" }}
              </span>
            </div>
          </template>
        </b-table>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      fieldsClass: [
        { key: "Class", label: "Class" },
        { key: "Students", label: "Students" },
        { key: "TotalSubjects", label: "Total Subjects" },
        { key: "Lectures", label: "Lectures " },
      ],
      Class: [
        {
          Class: " 10A",
          Students: "12",
          TotalSubjects: "15",
          Lectures: "3",
        },
        {
          Class: " 10E",
          Students: "22",
          TotalSubjects: "15",
          Lectures: "5",
        },
        {
          Class: " 10F",
          Students: "29",
          TotalSubjects: "15",
          Lectures: "9",
        },
        {
          Class: "10B",
          Students: "32",
          TotalSubjects: "15",
          Lectures: "13",
        },
        {
          Class: " 10C",
          Students: "14",
          TotalSubjects: "15",
          Lectures: "7",
        },
        {
          Class: " 10D",
          Students: "08",
          TotalSubjects: "15",
          Lectures: "18",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style></style>
